.wrapper {
  width: 80%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  min-height: 600px;

  margin: 0 auto;
  padding: 30px 60px;
}

.main {
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: row;

  border: 1px solid grey;

  .canvas {
    flex: auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    flex: 0 0 380px;
    height: 100%;

    border-left: 1px solid #c5c5c5;

    display: flex;
    flex-direction: column;

    .title {
      flex: 0 1 auto;
      padding: 10px 15px;
      font-size: 24px;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      font-variant: small-caps;
      border-bottom: 1px solid #c5c5c5;
    }

    .radio_groups {
      flex: 0 1 auto;

      .radio {
        padding: 10px;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid #c5c5c5;

        &.active {
          background-color: #e7e7e7;
        }
      }
    }

    .filters {
      flex: 0 1 auto;

      border-bottom: 1px solid #c5c5c5;

      .filter {
        display: flex;
        flex-direction: row;
        margin: 10px;

        .filter_label {
          flex: 0 0 100px;
          padding-right: 10px;
          line-height: 24px;
          text-align: right;
          color: #c5c5c5;
        }

        .filter_ctrl {
          flex: auto;
        }
      }
    }

    .empty {
      flex: 1 1 auto;
      display:flex;
      justify-content:center;
      align-items: center;
    }

    .papers {
      flex: 1 1 auto;
      padding: 0 10px 24px 10px;
      overflow-y: scroll;

      .paper {
        padding: 10px 0;
        color: #797979;
        border-bottom: 1px solid #c5c5c5;

        &:last-of-type {
          border-bottom: none;
        }

        .paper_title {
          font-weight: bold;
        }

        .paper_venue {
          margin-top: 6px;
          font-weight: bold;
        }

        .paper_author, .paper_affiliation, .paper_field {
          margin-top: 3px;
          font-style: italic;
          color: grey;

          span {
            margin-right: 5px;

            &.link {
              cursor: pointer;
              color: #1890ff;
            }

            &.active {
              font-weight: bold;
            }

            &:after {
              content: ';';
            }

            &:last-of-type {
              margin-right: 0;

              &:after {
                content: '';
              }
            }
          }
        }
      }
    }

    .patents {
      flex: 1 1 auto;
      padding: 0 10px 24px 10px;
      overflow-y: scroll;

      .patents_header {
        margin-top: 10px;
        font-weight: bold;
        color: crimson;
      }

      .patent {
        padding: 10px 0;
        color: #797979;
        border-bottom: 1px solid #c5c5c5;

        &:last-of-type {
          border-bottom: none;
        }

        .patent_tit {
          font-weight: bold;
        }

        .patent_info {
          margin-top: 3px;
          font-style: italic;
          color: grey;
        }

        .patent_inv, .patent_app {
          margin-top: 3px;
          //font-style: italic;
          color: grey;

          span {
            margin-right: 5px;

            &.link {
              cursor: pointer;
              color: #1890ff;
            }

            &.active {
              font-weight: bold;
            }

            &:after {
              content: ';';
            }

            &:last-of-type {
              margin-right: 0;

              &:after {
                content: '';
              }
            }
          }
        }
      }
    }
  }
}

.g6-minimap {
  position: absolute;
  bottom: 4px;
  left: 5px;
  border: 1px solid grey;
}

.g6-tooltip {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 10px;
  color: #545454;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 8px;
  box-shadow: rgb(174, 174, 174) 0 0 10px;

  .count {
    font-size: 16px;
    font-weight: bold;
  }

  .name {
    font-size: 12px;
  }
}

.slogan {
  position: fixed;
  height: 100%;
  width: 60px;
  right: 0;
  top: 0;
  padding: 10px;
  background-color: #c5c5c5;
  color: #ffffff;
  font-family: Abadi, Arial, sans-serif;
  font-size: 28px;
  writing-mode: vertical-lr;

  .logo {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
    background: url('/static/logo.png');
    background-size: contain;
  }
}

.plot {

  .texts {
    fill: #9d9d9d;
  }

  .company {
    font: 500 64px Roboto;
    //stroke: #4096EE;
    //fill: #ffffff;
    fill: #ddd
  }

  .year {
    font: 500 156px Roboto;
    fill: #ddd;
  }

  .active {
    fill: #aaa;
  }

  .overlay_resize {
    fill: none;
    pointer-events: all;
    cursor: ew-resize;
  }

}
@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(51, 51, 51, 1);@text-color-secondary: rgba(51, 51, 51, 0.8);@font-family-no-number: -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Noto Sans SC', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Microsoft YaHei', 'Noto Sans SC', 'Chinese Quote', 'PingFang SC', 'Hiragino Sans GB', sans-serif;@font-family: @font-family-no-number;@code-family: 'Roboto Mono', 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;@font-size-base: 12px;@font-size-lg: @font-size-base + 2px;@font-size-sm: @font-size-base - 2px;@line-height-base: 1.4;@border-radius-base: 0px;@border-radius-sm: 0px;@form-item-margin-bottom: 12px;
*, p {
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(51, 51, 51, 1);@text-color-secondary: rgba(51, 51, 51, 0.8);@font-family-no-number: -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Noto Sans SC', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Microsoft YaHei', 'Noto Sans SC', 'Chinese Quote', 'PingFang SC', 'Hiragino Sans GB', sans-serif;@font-family: @font-family-no-number;@code-family: 'Roboto Mono', 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;@font-size-base: 12px;@font-size-lg: @font-size-base + 2px;@font-size-sm: @font-size-base - 2px;@line-height-base: 1.4;@border-radius-base: 0px;@border-radius-sm: 0px;@form-item-margin-bottom: 12px;